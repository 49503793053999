import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { ProductDetailsDto } from '@ev-portals/dp/frontend/shared/api-client';
import { AuthenticationService } from '@ev-portals/dp/frontend/shared/auth/data-access';
import { BackLinkComponent, ShimmerEffectDirective } from '@ev-portals/ev/frontend/ui-library';

import { ArticleSelectorComponent } from './article-selector';

@Component({
  standalone: true,
  imports: [CommonModule, ArticleSelectorComponent, BackLinkComponent, ShimmerEffectDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'dp-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductInfoComponent {
  #authenticationService = inject(AuthenticationService);

  @Input() product: ProductDetailsDto;
  @Input() loading = false;
  @Output() navigateBack = new EventEmitter<ProductDetailsDto>();
  @Output() requestSample = new EventEmitter<ProductDetailsDto>();
  @Output() requestPrice = new EventEmitter<ProductDetailsDto>();
}
