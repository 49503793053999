import { CommonModule } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularModule } from '@atoms/angular';
import {
  // DocumentInquiryRequestBodyDto,
  DocumentDto,
  DocumentInquiryRequestBodyDto,
  ProductDetailsDto,
} from '@ev-portals/dp/frontend/shared/api-client';
import { ModalDialogComponent } from '@ev-portals/ev/frontend/ui-library';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    AngularModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    ModalDialogComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'dp-request-access-dialog',
  templateUrl: './request-access-dialog.component.html',
  styleUrls: ['./request-access-dialog.component.scss'],
})
export class RequestAccessDialogComponent implements OnInit {
  @Input() product: ProductDetailsDto;
  @Input() document: DocumentDto;
  @Input() pendingStatus: boolean;
  @Output() closeDialog = new EventEmitter<void>();
  @Output() requestAccess = new EventEmitter<DocumentInquiryRequestBodyDto>();
  dialogTitle = 'Request Access for';
  descLabel = 'Why do you need access to this document?';
  descPlaceholder = 'Start typing...';
  accessRequestForm: FormGroup<DocumentRequestAccess>;
  isDocumentPCF = false;
  public get submitIsDisabled(): boolean {
    return this.accessRequestForm.invalid || this.pendingStatus;
  }

  constructor(private dialogFormBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.isDocumentPCF = this.document.documentType === 'Product Carbon Footprint – Statement';
    this.accessRequestForm = this.#getInitialFormGroup();
  }

  onSubmit(): void {
    if (this.submitIsDisabled) {
      return;
    }

    this.requestAccess.emit({
      documentId: this.document.id,
      productId: this.product.id,
      productName: this.product.name,
      sbu: this.product.sbu,
      requestDescription: this.accessRequestForm.controls['description'].value ?? '',
      documentName: this.document.name,
      documentType: this.document.documentType as DocumentInquiryRequestBodyDto['documentType'],
    });
  }

  #getInitialFormGroup(): FormGroup<DocumentRequestAccess> {
    return this.dialogFormBuilder.group({
      description: ['', Validators.required],
      agreedToTerms: [
        { checked: false },
        this.isDocumentPCF ? this.#validateAtomCheckBox : Validators.nullValidator,
      ],
    }) as FormGroup<DocumentRequestAccess>;
  }

  #validateAtomCheckBox(control: AbstractControl): { invalidAgreedToTerms: boolean } | null {
    if (control.value['checked'] === false) {
      return { invalidAgreedToTerms: true };
    }
    return null;
  }
}

export interface DocumentRequestAccess {
  description: FormControl<string>;
  agreedToTerms?: FormControl<boolean | null>;
}
