import { CommonModule } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  signal,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AngularModule } from '@atoms/angular';
import { ProductDetailsDto } from '@ev-portals/dp/frontend/shared/api-client';
import { CartProxyService } from '@ev-portals/dp/frontend/shared/data-access';
import { AnalyticsService, FeedbackMessageService } from '@ev-portals/dp/frontend/shared/util';
import {
  QuantitySelectorComponent,
  ShimmerEffectDirective,
} from '@ev-portals/ev/frontend/ui-library';
import { PricePipeModule, quantityValidators } from '@ev-portals/ev/frontend/util';
import { debounceTime, map, startWith } from 'rxjs/operators';

/**
 * Note: this is a smart component
 */
@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AngularModule,
    QuantitySelectorComponent,
    PricePipeModule,
    ShimmerEffectDirective,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'dp-article-selector',
  templateUrl: './article-selector.component.html',
  styleUrls: ['./article-selector.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleSelectorComponent implements OnChanges {
  #fb = inject(FormBuilder);
  #feedbackMessageService = inject(FeedbackMessageService);
  // TODO: add later
  // private productDetailsFacade = inject(ProductDetailsFacade);
  #cartProxyService = inject(CartProxyService);
  #analyticsService = inject(AnalyticsService);

  @Input() loading = false;
  @Input() product: ProductDetailsDto;
  @Output() requestPrice = new EventEmitter<void>();
  @Output() requestSample = new EventEmitter<void>();

  #minQuantity = 1;
  dropdownRerender = 0;
  selectorPlaceholder = 'Select a value';
  $pricePermissionMessage = signal<string | null>(null);
  successMessageDetails: { quantity: number } | null = null;
  fg: FormGroup<ArticleSelectorForm> = this.#getEmptyFormGroup();
  selectedArticle$ = this.fg.valueChanges.pipe(
    startWith(this.fg.value),
    // This fixes if the server responds too quickly
    debounceTime(100),
    // Note: this is a fix for a very weird bug, seems like the formValue sometimes stays null, even if this.fg.value is already there...
    map(() => this.fg.value),
    map(({ articleId }) => {
      const article = this.product?.articles?.find(article => article.id === articleId);

      return article;
    }),
  );

  constructor() {
    this.#initRequestErrorListener();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['product']) {
      this.#initDefaultArticle();
    }
  }

  onSubmit(): void {
    if (this.fg.invalid) {
      throw new Error(`[ArticleSelectorComponent::onSubmit] form is invalid`);
    }

    const change = {
      articleId: this.fg.value.articleId as string,
      quantity: this.fg.value.quantity as number,
    };

    this.#analyticsService.trackEvent(
      'purchase',
      'addArticleToCart',
      'action',
      JSON.stringify(change),
    );

    this.#cartProxyService.addArticles(change.articleId, +change.quantity);

    this.fg.controls.quantity.patchValue(this.#minQuantity);
    this.#feedbackMessageService.showSuccessMessage(
      change.quantity === 1
        ? 'Item has been successfully added to the cart'
        : `${change.quantity} ` + 'items have been successfully added to the cart',
    );
  }

  #initRequestErrorListener(): void {
    // used cartProxyService to avoid dependency between product and purchase
    this.#cartProxyService.requestError$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.#feedbackMessageService.showErrorMessage();
    });
  }

  #initDefaultArticle(): void {
    this.fg.controls.articleId.setValue(this.product.articles.at(0)?.id ?? null);
  }

  #getEmptyFormGroup(): FormGroup<ArticleSelectorForm> {
    return this.#fb.group({
      articleId: [null, Validators.required],
      quantity: [this.#minQuantity, quantityValidators],
    }) as FormGroup<ArticleSelectorForm>;
  }
}

export interface ArticleSelectorForm {
  articleId: FormControl<string | null>;
  quantity: FormControl<number | null>;
}
