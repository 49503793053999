import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  DestroyRef,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { AngularModule } from '@atoms/angular';
import {
  ProductDetailsFacade,
  ProductDocumentsFacade,
} from '@ev-portals/dp/frontend/product/data-access';
import {
  DocumentDto,
  DocumentInquiryRequestBodyDto,
  mockProductDetails2,
  mockSimplifiedDocumentList,
  ProductDetailsDto,
  RequestSupportApiService,
} from '@ev-portals/dp/frontend/shared/api-client';
import {
  AnalyticsService,
  // EventAction,
  FeedbackMessageService,
  NavigationService,
} from '@ev-portals/dp/frontend/shared/util';
import { ShimmerEffectDirective } from '@ev-portals/ev/frontend/ui-library';
import { PianoEventType } from '@ev-portals/ev/frontend/util';
import { BehaviorSubject, catchError, combineLatest, filter, map, startWith, take } from 'rxjs';

import { RequestAccessDialogComponent } from './dialogs/request-access-dialog/request-access-dialog.component';
import { ProductAttributesComponent } from './product-attributes';
import { ProductDocumentsComponent } from './product-documents';
import { ProductInfoComponent } from './product-info';

@Component({
  standalone: true,
  imports: [
    ProductInfoComponent,
    RequestAccessDialogComponent,
    CommonModule,
    AngularModule,
    ShimmerEffectDirective,
    FormsModule,
    // AtomsDefinitionsModule,
    RouterModule,
    ProductDocumentsComponent,
    ShimmerEffectDirective,
    ProductAttributesComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'dp-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
  // Currently dialog does not close
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductDetailsComponent implements OnInit, OnDestroy {
  #route = inject(ActivatedRoute);
  #productDetailsFacade = inject(ProductDetailsFacade);
  #productDocumentsFacade = inject(ProductDocumentsFacade);
  #navigationService = inject(NavigationService);
  #feedbackMessageService = inject(FeedbackMessageService);
  #requestApiService = inject(RequestSupportApiService);
  #analyticsService = inject(AnalyticsService);
  #destroyRef = inject(DestroyRef);

  product$ = this.#productDetailsFacade.selectedProduct$.pipe(
    startWith(mockProductDetails2),
    catchError(error => {
      console.error('Error fetching product:', error);
      if (error.status === 403) {
        this.#navigationService.navigateToForbiddenPage();
      } else if (error.status === 404) {
        this.#navigationService.navigateToNotFoundPage();
      }
      throw error;
    }),
  );
  localModifications$ = new BehaviorSubject<{
    [id: string]: Partial<DocumentDto>;
  }>({});
  documentList$ = combineLatest([
    this.#productDocumentsFacade.documentList$.pipe(startWith(mockSimplifiedDocumentList)),
    this.localModifications$,
  ]).pipe(
    map(([documentList, localModifications]) => {
      if (!documentList) {
        return null;
      }

      // Loop through the local modifications and apply them
      Object.entries(localModifications).forEach(([id, modification]) => {
        documentList.documentsMap[id] = {
          ...documentList.documentsMap[id],
          ...modification,
        };
      });

      // StructuredClone is needed, otherwise, it won't trigger the change detection
      return structuredClone(documentList);
    }),
  );

  docListLoading$ = this.#productDocumentsFacade.loading$;
  loading$ = this.#productDetailsFacade.loading$;
  pending = false;
  // This also controls, whether the dialog is shown
  requestAccessTargetDocument: DocumentDto | undefined;

  ngOnInit(): void {
    // Selecting the proper product based on the id path param
    this.#route.paramMap.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(paramMap => {
      this.#productDetailsFacade.selectProduct(paramMap.get('id'));
    });
    // Analytics
    this.#productDetailsFacade.selectedProduct$
      .pipe(
        filter(p => !!p),
        take(1),
      )
      .subscribe(product => {
        if (product) {
          this.#analyticsService.viewProductDetails(product.name, product.id);
        }
      });
  }

  ngOnDestroy(): void {
    this.#productDetailsFacade.selectProduct(null);
  }

  onRequestSample(product: ProductDetailsDto): void {
    this.#navigationService.navigateToRequestSample(product.id);
  }

  // Show the place order dialog
  onPlaceOrder(product: ProductDetailsDto): void {
    // TODO: add later
    console.warn(`[::onPlaceOrder] Not implemented yet.`);
  }

  onRequestPrice(product: ProductDetailsDto): void {
    // TODO: add later
    // this.analyticsService.trackEvent(
    //   'product-details',
    //   'requestPriceClick',
    //   'action',
    //   JSON.stringify({ productId: product.id })
    // );
    this.#navigationService.navigateToRequestPrice(product.id);
  }

  onNavigateBack(product: ProductDetailsDto): void {
    this.#navigationService.navigateToProducts();
  }

  onDownload(doc: DocumentDto, product: ProductDetailsDto): void {
    this.#trackDocumentEvent('documentDownloadClick', 'download', doc, product);

    // Opening in a new tab:
    window.open(doc.downloadUrl, '_blank');
    /**
     * We should test this after we have the proper download links from the server with
     * proper Content-Disposition Header
     * https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Disposition
     *
     * Down below you can find an alternative solution, which opens the files in the same window, instead of a new tab:
     */
    // const link = document.createElement('a');
    // link.href = doc.downloadUrl;
    // link.download = `${doc.name}.${doc.fileType}`;
    // link.click();
  }

  onClickRequestAccess(doc: DocumentDto, product: ProductDetailsDto): void {
    this.#trackDocumentEvent('documentRequestClick', 'action', doc, product);

    this.requestAccessTargetDocument = doc;
  }

  onSubmitRequestAccess(requestAccessDto: DocumentInquiryRequestBodyDto): void {
    console.warn(`[::onSubmitRequestAccess] Not implemented yet.`);
    // TODO: add later, wait for implementation
    this.pending = true;
    this.#requestApiService.documentAccessRequest({ body: requestAccessDto }).subscribe({
      complete: () => {
        this.#feedbackMessageService.showSuccessMessage('Request successfully sent');
        // Update the local state to PENDING
        this.localModifications$.next({
          ...this.localModifications$.value,
          [requestAccessDto.documentId]: { accessibility: 'PENDING' },
        });
        this.pending = false;
        this.requestAccessTargetDocument = undefined;
      },
      error: (error: HttpErrorResponse) => {
        const errorMessage = error.error?.message || undefined;
        this.#feedbackMessageService.showErrorMessage(errorMessage);
        this.pending = false;
      },
    });
  }

  #trackDocumentEvent(
    action: any, //EventAction,
    type: PianoEventType,
    doc: DocumentDto,
    product: ProductDetailsDto,
  ): void {
    console.warn(`[::trackDocumentEvent] Not implemented yet.`);
    this.#analyticsService.trackEvent(
      'product-details',
      action,
      type,
      JSON.stringify({
        documentName: doc.name,
        productId: product.id,
        productName: product.name,
      }),
    );
  }
}
