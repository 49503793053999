<h3>Product Carbon Footprint Statement - Terms of Use</h3>
<p class="title sub-heading">
  BASF terms of use for the download of BASF Environmental Evaluation Data:
</p>
<ol>
  <li class="title-heading">General</li>
  <ol>
    <li>
      <p>
        BASF SE, Carl-Bosch-Str. 38, 67056 Ludwigshafen (“BASF”) and its
        affiliates possess environmental evaluation data (such as life cycle
        inventory (LCI) and life cycle impact assessment (LCIA) data, including
        cradle to gate product carbon footprint for certain products of BASF
        (“BASF Environmental Evaluation Data”). Although BASF is convinced that
        BASF Environmental Evaluation Data can only be interpreted correctly as
        part of a comprehensive and comparative environmental analysis, BASF is
        willing to provide User with BASF Environmental Evaluation Data via the
        download of corresponding data files subject to these terms of use.
      </p>
    </li>
    <li>
      <p>
        BASF provides the BASF Environmental Evaluation Data exclusively to its
        commercial customers in their capacity as “entrepreneurs” in accordance
        with § 14 para 1 BGB (German Civil Code) (“User”) as applicable via the
        download functionality of corresponding data files subject to these
        terms of use. An entrepreneur is a natural or legal person or a
        partnership with legal capacity who or which, when entering into a legal
        transaction, acts in the exercise of its trade, business or profession.
        “Consumers” in accordance with § 13 BGB may not receive the BASF
        Environmental Evaluation Data.
      </p>
    </li>
  </ol>

  <li class="title-heading">
    Access to and provision of the BASF Environmental Evaluation Data
  </li>
  <ol>
    <li>
      <p>
        The download and use of the BASF Environmental Evaluation Data by the
        User is voluntary. Access to the download is subject to the acceptance
        by the User of these terms of use and User having an active account in
        the BASF platform “EV Member Area” enabling a purchase of the goods from
        BASF and or its affiliates to which the BASF Environmental Evaluation
        Data refers to. The User declares at the same time that it has obtained
        access to the download of BASF Environmental Data solely for its
        business purposes as further described in these terms of use. Any other
        use of the BASF Environmental Data by the User or through or by any
        third parties is prohibited.
      </p>
    </li>
    <li>
      <p>
        The terms of use are concluded in English. These terms of use are stored
        by BASF and are accessible to the User at any time on the respective
        download center functionality.
      </p>
    </li>
    <li>
      <p>
        BASF shall provide reasonable access to and use of the BASF
        Environmental Evaluation Data via the download of corresponding data
        files. BASF provides access to the download of BASF Environmental
        Evaluation Data in a hosted BASF Azure Cloud environment which allows
        the User to download the BASF Environmental Evaluation Data over the
        internet without having to install and operate the software on its own
        IT-devices.
      </p>
    </li>
    <li>
      <p>
        BASF shall use reasonable endeavors to maintain the availability of the
        download functionality of BASF Environmental Evaluation Data by the User
        at the gateway between the public internet and the network of BASF’s
        hosting servers. The download functionality of BASF Environmental
        Evaluation Data is “available” if it is usable at the gateway between
        the public internet and the network of BASF’s hosting servers.
      </p>
    </li>
    <li>
      <p>
        BASF is free to restrict access to the download of BASF Environmental
        Evaluation Data in whole or in part, temporarily or permanently, due to
        maintenance work, capacity concerns and other events beyond its control.
        Due to the nature of the internet and computer systems, BASF does not
        ensure that the download functionality of BASF Environmental Evaluation
        Data is operating trouble-free and does not assume any liability for the
        availability of the application or services.
      </p>
    </li>
  </ol>

  <li class="title-heading">
    Rights of use to BASF Environmental Evaluation Data, Customer Environmental
    Evaluation Data, Confidentiality
  </li>
  <ol>
    <li>
      <p>
        BASF is and will be the sole and exclusive owner of the BASF
        Environmental Evaluation Data. Nothing in these terms of use shall grant
        any license or any other rights to the BASF Environmental Evaluation
        Data, except for the limited right to use the BASF Environmental
        Evaluation Data solely as set out in these terms of use.
      </p>
    </li>
    <li>
      <p>
        User shall use the BASF Environmental Evaluation Data only for the
        calculation of environmental footprints and/or carbon footprints of its
        own products, which are produced with BASF products (“Customer
        Environmental Evaluation Data”). User undertakes to keep the BASF
        Environmental Evaluation Data confidential and not to forward and/or
        disclose it to any third party unless, and to the extent, approved for
        disclosure by the prior written consent of BASF in its sole discretion
        or permissible in accordance with below sub-section (3).
      </p>
    </li>
    <li>
      <p>
        Notwithstanding the foregoing, User may forward BASF Environmental
        Evaluation Data to third parties or User’s Affiliates, if and to the
        extent this is necessary for the calculation of User’s and/or its
        Affiliates’ Customer Environmental Evaluation Data, and provided that
        User ensures, and shall be responsible and liable, including towards
        BASF, for, the full compliance by such third parties and/or its
        Affiliates with the confidentiality obligations and use restrictions set
        out in these terms of use at all times. "Affiliate" means any person or
        entity that directly or indirectly controls, is controlled by or is
        under common control with a person or entity. In this context, "control"
        means the ownership or control of more than fifty percent (50%) of the
        voting stock or interest of the subject entity. User shall be
        responsible and liable (including towards BASF) for its Affiliates’
        and/or the respective third party’s compliance with the confidentiality
        obligations and/or use restriction, and a breach of confidentiality or
        use restriction by its Affiliates and/or the third party shall be
        treated in the same way as a corresponding breach by User.
      </p>
    </li>
    <li>
      <p>
        Further, User commits to BASF that it discloses and/or forwards Customer
        Environmental Evaluation Data that is developed based on or using BASF
        Environmental Evaluation Data to third parties only if (i) BASF
        Environmental Evaluation Data cannot be extracted from it, and if (ii)
        no conclusion as to BASF and any of its products can be drawn and no
        correlation can be established from Customer Environmental Evaluation
        Data to BASF Environmental Evaluation Data.
      </p>
    </li>
    <li>
      <p>
        The confidentiality obligations and/or use restrictions shall not apply
        for BASF Environmental Evaluation Data that the User can prove, i. is or
        becomes generally available to the public through no fault of User or
        any violation of these terms of use; ii. was rightfully in the
        possession of User without confidentiality or non-use restrictions prior
        to the initial download of such data; iii. is lawfully received by User
        without confidentiality or non-use restrictions from an unaffiliated
        third party without breach of any confidentiality obligations, statutory
        provisions or official order.
      </p>
    </li>
    <li>
      <p>
        The User may disclose BASF Environmental Evaluation Data to the extent
        compelled to do so pursuant to any involuntary court order or statutory
        or regulatory authority, but only if User (a) provides prompt prior
        written notice to BASF, (b) fully cooperates with BASF to obtain a
        protective order or other appropriate limitations to prevent or narrow
        disclosure and (c) makes such disclosure solely to the extent mandatory
        under such order or authority.
      </p>
    </li>
  </ol>

  <li class="paragraph-heading title-heading">
    Nature of BASF Environmental Evaluation Data and Liability
  </li>
  <ol>
    <li>
      <p>
        BASF Environmental Evaluation Data reflects the situation at the time
        such data has been collected and BASF shall be under no obligation to
        update the BASF Environmental Evaluation Data. BASF Environmental
        Evaluation Data is based on certain presumptions, approximations and
        limitations (e.g. an expiry date) as maybe further set out in the
        respective BASF Environmental Evaluation Data that consequently may
        impact the accuracy and reliability of the BASF Environmental Evaluation
        Data in the individual case.
      </p>
    </li>

    <li>
      <p>
        BASF ENVIRONMENTAL EVALUATION DATA IS PROVIDED "AS IS". THE PROVISION OF
        BASF ENVIRONMENTAL EVALUATION DATA SHALL NOT CONSTITUTE ANY
        REPRESENTATION OR WARRANTY OF ANY KIND, WHETHER EXPRESSED OR IMPLIED,
        INCLUDING WITHOUT LIMITATION IMPLIED WARRANTIES OF FITNESS FOR A
        PARTICULAR PURPOSE OR MERCHANTABILITY, AND SHALL NOT RELIEVE USER FROM
        UNDERTAKING ITS OWN INVESTIGATIONS AND TESTS. IN PARTICULAR, BASF
        ENVIRONMENTAL EVALUATION DATA DO NOT CONSTITUTE AN AGREED QUALITY OF
        BASF PRODUCTS (“BESCHAFFENHEITSVEREINBARUNG”).
      </p>
    </li>
    <li>
      <p>
        ACCORDINGLY, ANY LIABILITY OF BASF WITH REGARD TO DAMAGES OR LOSSES,
        WHICH USER SUFFERS, THROUGH THE USE OR THROUGH USER’S RELIANCE UPON THE
        ACCURACY, QUALITY, COMPLETENESS OR FITNESS OF BASF ENVIRONMENTAL
        EVALUATION DATA FOR A PARTICULAR PURPOSE SHALL BE EXCLUDED TO THE
        FULLEST EXTENT PERMITTED BY APPLICABLE LAW. This exclusion / limitation
        of liability shall not apply if under the applicable law, a liability
        cannot be excluded/limited, e.g. in cases (i) of willful misconduct or
        gross negligence, (ii) of injury to life, body or health or (iii) the
        product liability law mandates an unlimited liability.
      </p>
    </li>
    <li>
      <p>
        User agrees to indemnify, defend and hold harmless BASF and/or its
        Affiliates and their respective employees from any damages arising from
        claims raised by third parties, should these claims be the result of the
        third parties using and relying upon BASF Environmental Evaluation Data
        or Customer Environmental Evaluation Data received from User, unless and
        only to the extent that such damages are due to the wilful misconduct or
        gross negligence of BASF and/or its Affiliates and, in case of gross
        negligence of BASF and/or its Affiliates, were knowable and reasonably
        foreseeable by BASF at the time BASF Environmental Evaluation Data was
        made available for download.
      </p>
    </li>
  </ol>

  <li class="paragraph-heading title-heading">Data Protection</li>
  <p>
    BASF complies with all applicable data protection laws. For further
    information, please refer to the applicable privacy policy.
  </p>
  <li class="paragraph-heading title-heading">Changes to Terms of Use</li>
  <ol>
    <li>
      <p>
        BASF reserves the right to amend these terms of use at any time with
        regard to equivalence disruptions or regulatory gaps and to subject the
        use of the BASF Environmental Evaluation Data by User to new or further
        terms of use, whereby the aforementioned amendments will expressly not
        relate to the BASF Environmental Evaluation Data already provided by
        BASF. Also excluded in the context of this section are unilateral
        changes affecting the main contractual obligations of the parties.
      </p>
    </li>
    <li>
      <p>
        BASF shall notify User of the amended terms of use prior to the planned
        effective date and shall separately indicate the new provisions as well
        as the date of the planned effective date. At the same time, BASF shall
        grant User a reasonable period of notice of at least two (2) weeks to
        terminate the contractual relationship with BASF based on these terms of
        use without prior notice if User does not agree with the amended terms
        of use. Upon notification of the amended terms of use, BASF shall inform
        User of its right to terminate the contract, the applicable period of
        notice and the consequences of silence.
      </p>
    </li>
    <li>
      <p>
        If User does not give notice of termination within the aforementioned
        period, the amended terms of use shall apply upon expiry of the period.
        User may exercise its right of termination, for example, by providing a
        notification to BASF in writing.
      </p>
    </li>
  </ol>

  <li class="paragraph-heading title-heading">
    Term of Use and Termination, Miscellaneous
  </li>
  <ol>
    <li>
      <p>
        These terms of use are concluded for an indefinite period and can be
        terminated by each party at any time with immediate effect without
        cause, however, all confidentiality obligations and use restrictions of
        User shall survive 10 years after termination. BASF may terminate the
        User’s rights to access the download functionality of BASF Environmental
        Evaluation Data at any time without prior written notice.
      </p>
    </li>
    <li>
      <p>
        Should one or more provisions of these terms of use be invalid or
        unenforceable due to violation of applicable law or another reason, the
        rest of the terms of use shall remain valid. The invalid or
        unenforceable provision shall be replaced by a valid, enforceable
        provision, which most approximately represents the mutual understanding
        of the parties.
      </p>
    </li>
    <li>
      <p>
        The validity, interpretation and implementation of this Agreement shall
        be governed by the substantive laws of the Federal Republic of Germany,
        excluding (i) its provisions on renvoi under its conflict of laws rules
        and (ii) the United Nations Convention on Contracts for International
        Sale of Goods of April 11, 1980.
      </p>
    </li>
    <li>
      <p>
        Ludwigshafen / Rhine shall be the exclusive place of jurisdiction for
        all disputes arising from or in connection with these terms of use.
      </p>
    </li>
  </ol>
</ol>
