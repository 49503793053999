@if (selectedArticle$ | async; as selectedArticle) {
  @if (product) {
    <form [formGroup]="fg">
      <div class="properties-grid">
        <div class="row">
          <div class="title" [evShimmerEffect]="loading">Choose size:</div>
          <div class="container-articles" [evShimmerEffect]="loading">
            <atoms-dropdown
              [placeholder]="selectorPlaceholder"
              class="articles-dropdown"
              atomsControl2
              formControlName="articleId"
            >
              @for (article of product.articles; track article) {
                <atoms-option [value]="article.id">
                  <span class="option-wrapper flex gap-2 items-center">
                    <span class="cart-icon w-3 h-5 items-center"></span>
                    {{ article.name }} - {{ article.packagingSize.value }}
                    {{ article.packagingSize.unitOfMeasurement }}
                  </span>
                </atoms-option>
              }
            </atoms-dropdown>
          </div>
        </div>

        <div class="row">
          <div class="title" [evShimmerEffect]="loading"
            >Price per {{ selectedArticle.packagingSize.unitOfMeasurement }}.
            incl. GST:</div
          >
          <div class="container-price" [evShimmerEffect]="loading">
            <div class="price flex flex-wrap items-baseline gap-2">
              <div class="highlight">
                {{ selectedArticle.grossPricePerUnit | evPrice }}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="title" [evShimmerEffect]="loading">Quantity:</div>
          <div class="flex min-w-fit" [evShimmerEffect]="loading">
            <ev-quantity-selector
              [quantityFormControl]="fg.controls.quantity"
            ></ev-quantity-selector>
          </div>
        </div>

        <!-- Action Buttons -->
        <div class="actions pt-9 flex gap-2">
          <atom-button-submit
            data-cy="add-to-cart-button"
            [disabled]="fg.invalid"
            [evShimmerEffect]="loading"
            (click)="onSubmit()"
          >
            Add to cart
          </atom-button-submit>
        </div>
      </div>
    </form>
  }
}

<!-- Templates -->
<ng-template #inlineSpinner>
  <atom-progress-spinner inline></atom-progress-spinner>
</ng-template>
