import { CommonModule } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { DocumentDto } from '@ev-portals/dp/frontend/shared/api-client';
import { ColumnLayoutDirective } from '@ev-portals/ev/frontend/ui-library';
@Component({
  standalone: true,
  imports: [CommonModule, ColumnLayoutDirective],
  selector: 'dp-document-row',
  templateUrl: './document-row.component.html',
  styleUrls: ['./document-row.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DocumentRowComponent implements OnChanges {
  titleIcon = titleIconConfig.AVAILABLE;
  actionIcon = titleIconConfig.AVAILABLE;
  actionText = titleIconConfig.AVAILABLE;
  languagesString = '';

  @Input() document!: DocumentDto;
  @Input() columnLayout: string;
  @Output() download = new EventEmitter<DocumentDto>(); // productId
  @Output() requestAccess = new EventEmitter<DocumentDto>(); // productId

  ngOnChanges(): void {
    this.titleIcon = titleIconConfig[this.document.accessibility];
    this.actionIcon = actionIconConfig[this.document.accessibility];
    this.actionText = actionTextConfig[this.document.accessibility];
    this.languagesString = this.document.languages.join(', ');
  }

  onClickAction(): void {
    if (['AVAILABLE', 'UNLOCKED'].includes(this.document.accessibility)) {
      this.download.emit(this.document);
    } else if (
      this.document.accessibility === 'LOCKED' ||
      // Rejected request can be requested again, as it might be that contracts or reasons for sharing it change over time
      this.document.accessibility === 'UNAVAILABLE'
    ) {
      this.requestAccess.emit(this.document);
    }
  }
}

const titleIconConfig = {
  AVAILABLE: 'atom:basf:attachment',
  UNAVAILABLE: 'atom:basf:lock',
  UNLOCKED: 'atom:basf:lock_open',
  PENDING: 'atom:basf:lock',
  LOCKED: 'atom:basf:lock',
};

const actionIconConfig = {
  AVAILABLE: 'atom:basf:download',
  UNAVAILABLE: 'atom:basf:lock',
  UNLOCKED: 'atom:basf:download',
  PENDING: 'atom:basf:reload',
  LOCKED: 'atom:basf:lock',
};

const actionTextConfig = {
  AVAILABLE: 'Download',
  UNAVAILABLE: 'Request Rejected',
  UNLOCKED: 'Download',
  PENDING: 'Request Pending',
  LOCKED: 'Request Access',
};
