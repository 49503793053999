import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule, Sort } from '@angular/material/sort';
import { AngularModule } from '@atoms/angular';
import {
  DocumentCategoryDto,
  DocumentDto,
  DocumentListResponseDto,
} from '@ev-portals/dp/frontend/shared/api-client';
import {
  ColumnLayoutDirective,
  ShimmerEffectDirective,
  TableHeaderDirective,
} from '@ev-portals/ev/frontend/ui-library';
import { RerenderDirective } from '@ev-portals/ev/frontend/util';
import { BehaviorSubject, combineLatest, startWith } from 'rxjs';

import { DocumentRowComponent } from './document-row';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularModule,
    DocumentRowComponent,
    ShimmerEffectDirective,
    RerenderDirective,
    TableHeaderDirective,
    ColumnLayoutDirective,
    MatSortModule,
  ],
  selector: 'dp-product-documents',
  templateUrl: './product-documents.component.html',
  styleUrls: ['./product-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ProductDocumentsComponent implements OnInit, OnChanges {
  @Input() loading = false;
  @Input() documentList: DocumentListResponseDto;
  @Output() download = new EventEmitter<DocumentDto>(); // productId
  @Output() requestAccess = new EventEmitter<DocumentDto>(); // productId

  searchControlLabel = 'Search (by name, document type or region)';
  searchControlPlaceholder = 'Type to search';
  catControlLabel = 'Category';
  catControlPlaceholder = 'Select a category';

  columnLayout = '2fr 1fr 0.6fr 0.5fr 1fr';

  /**
   * This is the state of the list after filtering, but before sorting.
   * Sorting can be applied on it, without recalculating the filters.
   */
  #filteredDocumentList: DocumentListResponseDto;
  // Filters
  textSearchControl = new FormControl<string>('');
  #defaultLanguageControlValue = 'all';
  languageControl = new FormControl<string>(this.#defaultLanguageControlValue, {
    nonNullable: true,
  });
  // Sorting map for each category
  sortingMap = new Map<string, Sort>();

  categoryControl = new FormControl<string | null>(null);
  categoryDropdownRerenderer = 0;
  visibleDocumentList$ = new BehaviorSubject<DocumentListResponseDto | null>(null);
  #destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    // Initially set mockDocumentList for shimmer effect
    this.visibleDocumentList$.next(this.documentList);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['loading'].currentValue) {
      this.categoryControl.setValue(this.documentList.categories[0]?.id);
      this.#listenToFilterChanges();
    }
    this.visibleDocumentList$.next(this.documentList);
  }

  #listenToFilterChanges(): void {
    // We recalculate the filters whenever any of them changes + also the sorting
    combineLatest([
      this.textSearchControl.valueChanges.pipe(
        startWith(''),
        // Note: could be improved by adding debounceTime + loading visuals
        // Trigger the loading state instantly
        // tap(() => this.visibleDocumentList$.next(null)),
        // debounceTime(500)
      ),
      this.languageControl.valueChanges.pipe(startWith(this.#defaultLanguageControlValue)),
    ])
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(([textSearchValue, languageValue]) => {
        this.#applyFilters(textSearchValue, languageValue);
        this.applySorting();
      });
  }

  updateSortingRules(categoryId: string, sort: Sort): void {
    this.sortingMap.set(categoryId, sort);
    this.applySorting();
  }

  /**
   * Applies the sorting rules to the filteredDocumentList and emits a new visibleDocumentList$ value
   */
  applySorting(): void {
    if (!this.#filteredDocumentList) {
      this.visibleDocumentList$.next(null);
    }

    const sortedDocumentList = structuredClone(
      this.#filteredDocumentList,
    ) as DocumentListResponseDto;

    sortedDocumentList.categories.forEach(category => {
      const sort = this.sortingMap.get(category.id);

      if (!sort?.active || !sort?.direction) {
        return;
      }

      category.documentIds = category.documentIds.sort((idA, idB) => {
        const a = this.#getSortingWeight(
          sortedDocumentList.documentsMap[idA],
          sort.active as SortingType,
        );
        const b = this.#getSortingWeight(
          sortedDocumentList.documentsMap[idB],
          sort.active as SortingType,
        );

        if (sort.direction === 'asc') {
          return a < b ? -1 : 1;
        } else {
          return a > b ? -1 : 1;
        }
      });
    });

    this.visibleDocumentList$.next(sortedDocumentList);
  }

  #applyFilters(textSearchValue: string | null, languageFilter: string): void {
    this.#filteredDocumentList = structuredClone(this.documentList);

    // If there's not filter to apply, we don't need to do anything
    if (!textSearchValue && languageFilter === 'all') {
      return;
    }

    // Filter by language
    this.#filteredDocumentList.categories = this.#filteredDocumentList.categories.map(category => {
      category.documentIds = category.documentIds.filter(documentId => {
        const document = this.#filteredDocumentList.documentsMap[documentId];

        // Filter by language
        if (languageFilter !== 'all' && !document.languages.includes(languageFilter)) {
          return false;
        }

        if (
          textSearchValue &&
          !this.#includesSearchTerm(document.name, textSearchValue) &&
          !this.#includesSearchTerm(document.documentType, textSearchValue) &&
          !this.#includesSearchTerm(document.region ?? '', textSearchValue)
        ) {
          return false;
        }

        return true;
      });

      return category;
    });

    this.categoryDropdownRerenderer++;
  }

  #includesSearchTerm(originalText: string, searchTerm: string): boolean {
    return originalText.toLowerCase().includes(searchTerm.toLowerCase());
  }

  #getSortingWeight(document: DocumentDto, property: SortingType): string {
    if (property === 'language') {
      return document.languages.join('');
    } else if (property === 'accessibility') {
      return ['AVAILABLE', 'UNLOCKED', 'PENDING', 'LOCKED']
        .indexOf(document.accessibility)
        .toString();
    } else {
      return document[property] ?? '';
    }
  }

  trackCategoryById(index: number, element: DocumentCategoryDto): string {
    return element.id;
  }
}

type SortingType = 'name' | 'documentType' | 'region' | 'language' | 'accessibility';
