import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { ProductDetailsDto } from '@ev-portals/dp/frontend/shared/api-client';
import { ShimmerEffectDirective } from '@ev-portals/ev/frontend/ui-library';

@Component({
  standalone: true,
  imports: [CommonModule, ShimmerEffectDirective, NgOptimizedImage],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'dp-product-attributes',
  templateUrl: './product-attributes.component.html',
  styleUrls: ['./product-attributes.component.scss'],
})
export class ProductAttributesComponent {
  @Input() product: ProductDetailsDto;
  @Input() loading = false;

  showMore = false;
}
