@if (visibleDocumentList$ | async; as visibleDocumentList) {
  <div
    class="header flex max-sm:flex-col max-sm:justify-start max-sm:items-start md:flex-row md:justify-between md:items-center mb-4 gap-4"
  >
    <div class="title" [evShimmerEffect]="loading">
      <span>Product related documents</span>
    </div>
  </div>
  <div class="filters mb-6 flex flex-wrap gap-4 justify-between">
    <div class="text-search-container" [evShimmerEffect]="loading">
      <atoms-input
        data-cy="product-details-search"
        class="text-search"
        [label]="searchControlLabel"
        [placeholder]="searchControlPlaceholder"
        atomsControl2
        [formControl]="textSearchControl"
      ></atoms-input>
    </div>
  </div>

  <ng-container>
    <!-- Tabs - desktop view -->
    <atom-tabs data-cy="document-categories" class="hidden lg:block">
      @for (
        category of visibleDocumentList.categories;
        track trackCategoryById($index, category)
      ) {
        <section
          data-cy="document-category-tab"
          slot="tab"
          (click)="categoryControl.setValue(category.id)"
          [evShimmerEffect]="loading"
        >
          {{ category.name }} ({{ category.documentIds.length }})
        </section>
        <section slot="panel">
          @if (visibleDocumentList.categories) {
            <div
              class="header-row grid gap-3 select-none"
              evTableHeader
              [evColumnLayout]="columnLayout"
              matSort
              (matSortChange)="updateSortingRules(category.id, $event)"
              [evShimmerEffect]="loading"
            >
              <div
                data-cy="name-header"
                class="header-col"
                mat-sort-header="name"
              >
                Name
              </div>
              <div
                data-cy="document-type-header"
                class="header-col"
                mat-sort-header="documentType"
              >
                Document type
              </div>
              <div
                data-cy="region-header"
                class="header-col"
                mat-sort-header="region"
              >
                Region
              </div>
              <div
                data-cy="language-header"
                class="header-col"
                mat-sort-header="language"
              >
                Language
              </div>
              <div
                data-cy="accessibility-header"
                class="header-col"
                mat-sort-header="accessibility"
              >
                <div class="text-right w-full">Accessibility</div>
              </div>
            </div>

            @for (documentId of category.documentIds; track documentId) {
              <dp-document-row
                data-cy="dp-document-row"
                class="document-row ev-row"
                [ngClass]="{
                  'not-available': !['AVAILABLE', 'UNLOCKED'].includes(
                    documentList.documentsMap[documentId].accessibility
                  ),
                }"
                [columnLayout]="columnLayout"
                [document]="documentList.documentsMap[documentId]"
                (download)="download.emit($event)"
                (requestAccess)="requestAccess.emit($event)"
                [evShimmerEffect]="loading"
              ></dp-document-row>
            }
          } @else {
            <span>There are no available documents.</span>
          }
        </section>
      }
    </atom-tabs>

    <!-- Dropdown - mobile view -->
    <div class="mobile-view lg:hidden">
      <div [evShimmerEffect]="loading">
        <atoms-dropdown
          *evRerender="categoryDropdownRerenderer"
          [label]="catControlLabel"
          class="category-selector"
          [placeholder]="catControlPlaceholder"
          name="category-selector"
          atomsControl2
          [formControl]="categoryControl"
        >
          @for (
            category of visibleDocumentList.categories;
            track trackCategoryById($index, category)
          ) {
            <atoms-option [value]="category.id">
              {{ category.name }} ({{ category.documentIds.length }})
            </atoms-option>
          }
        </atoms-dropdown>
      </div>
      @for (
        category of visibleDocumentList.categories;
        track trackCategoryById($index, category)
      ) {
        @if (category.id === categoryControl.value) {
          <div class="documents-container mt-4">
            @if (category.documentIds.length) {
              @for (documentId of category.documentIds; track documentId) {
                <dp-document-row
                  class="document-row ev-row"
                  [document]="documentList.documentsMap[documentId]"
                  (download)="download.emit($event)"
                  (requestAccess)="requestAccess.emit($event)"
                  [evShimmerEffect]="loading"
                ></dp-document-row>
              }
            } @else {
              <span>There are no available documents.</span>
            }
          </div>
        }
      }
    </div>
  </ng-container>
}
