@if (product$ | async; as product) {
  @if (requestAccessTargetDocument) {
    <dp-request-access-dialog
      [document]="requestAccessTargetDocument"
      [product]="product"
      [pendingStatus]="pending"
      (closeDialog)="requestAccessTargetDocument = undefined"
      (requestAccess)="onSubmitRequestAccess($event)"
    >
    </dp-request-access-dialog>
  }

  <atom-breadcrumb size="medium">
    <atom-breadcrumb-item class="cursor-pointer" routerLink="/products">
      <span>Products</span>
    </atom-breadcrumb-item>
    <atom-breadcrumb-item [evShimmerEffect]="(loading$ | async) ?? true">{{
      product.name
    }}</atom-breadcrumb-item>
  </atom-breadcrumb>

  <div class="main-container flex flex-col gap-4 mt-5">
    <!-- Product Info -->
    <dp-product-info
      [product]="product"
      [loading]="(loading$ | async) ?? true"
      (navigateBack)="onNavigateBack($event)"
      (requestSample)="onRequestSample($event)"
      (requestPrice)="onRequestPrice($event)"
    ></dp-product-info>

    <!-- Product Attributes -->
    @if (product.sustainabilityAttributes.length) {
      <dp-product-attributes
        [product]="product"
        [loading]="(loading$ | async) ?? true"
      ></dp-product-attributes>
    }

    <!-- Product Documents -->
    @if (documentList$ | async; as documentList) {
      <dp-product-documents
        [documentList]="documentList"
        [loading]="
          ((loading$ | async) ?? true) || ((docListLoading$ | async) ?? true)
        "
        (download)="onDownload($event, product)"
        (requestAccess)="onClickRequestAccess($event, product)"
      ></dp-product-documents>
    }
  </div>
} @else {
  <atom-progress-spinner></atom-progress-spinner>
}
